import React, { useState } from 'react';
import __Img_Team from '../../Assets/Image/__teamBanner.png';
import __Img_Team_1 from '../../Assets/Image/team-1.jpeg';
import __Img_Team_2 from '../../Assets/Image/team-2.jpeg';
import __Img_Team_3 from '../../Assets/Image/team-3.jpeg';
import { Col, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';


const Team = () => {

    const [itemName, setItemName] = useState(false);
    const [itemName1, setItemName1] = useState(false);
    const [itemName2, setItemName2] = useState(false);


    return (
        <>
            <div className='team__'>
                <div className='team__banner' >
                    <div className="team-data">
                        <Fade bottom>
                            <div className='__img'>
                                <img src={__Img_Team} alt="img" />
                            </div>
                            <h3>Meet Our Team</h3>
                            <p>Our Dynamic and Diverse Team</p>
                        </Fade>
                        <div>
                            {/* <a href='#ourView'> Our Vision  <OpenInNew /> </a> */}
                        </div>
                    </div>
                </div>


                <div className='__sec_team'>
                    <div className='__custom_container'>
                        <Row>


                        </Row>

                    </div>
                </div>



            </div>
        </>
    );
}

export default Team;
