import React, { useEffect, useState } from 'react'
import { logo, title } from '../config/Config'
import { Link } from 'react-router-dom'
import { CloseTwoTone, Podcasts, Sort } from '@mui/icons-material'
// import img from ""

const Header = () => {

  const [isFixed, setIsFixed] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isActive, setIsActive] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const headerBanner = document.getElementById('header-banner');
      if (headerBanner) {
        const rect = headerBanner.getBoundingClientRect();
        const scrollThreshold = rect.height * 0.7;

        if (window.scrollY > scrollThreshold) {
          setIsFixed(true);
          setIsHidden(false);

        } else {
          setIsFixed(false);
        }

        if (window.scrollY > 100) {
          setIsHidden(true);

        } else {
          setIsHidden(false);

        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  const toggleNav = () => {
    setIsActive(!isActive);


    if (!isActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };




  const toggleRemove = () => {
    setIsActive(false);
  };

  const navLinks = [
    { text: 'Home', href: '/' },
    { text: 'About', href: '/about' },
    { text: 'Roadmap', href: '/roadmap' },
    { text: 'Our Services', href: '../../WHITEPAPER1.pdf', Button: false, pdf: true},
    { text: 'Contact', href: '/contact-us'},
    { text: 'White Paper', href: '../../WHITEPAPER.pdf', Button: false, pdf: true},
  ];


  return (
    <>
      <header className={`__pheader ${isFixed ? 'fixed' : ''} ${isHidden ? 'hidden' : ''}`}>
        <div className='__phLeft'>
          <Link to="/">
            <img src={logo} alt={title + "logo"} />
          </Link>
        </div>

        <div className='__phRight'>
          <ul className={`nav ${isActive ? 'phone-nav' : ''}`}>
            <li className='nav-item phoneHeader'>
              <Link to="/">
                <img src={logo} alt={title + "logo"} />
              </Link>
              <button type='button' className='toggleBtn' onClick={() => { toggleNav() }}> <CloseTwoTone /></button>
            </li>

            {navLinks.map((link, index) => (
              <li key={index} className={`nav-item`}>

                {link.pdf ?
                  <a href={link.href} onClick={() => { toggleRemove() }} className={`nav-link ${link.Button ? ' buttonThis' : ''}`} download>
                    {link.text}
                  </a>
                  :
                  <Link to={link.href} onClick={() => { toggleRemove() }} className={`nav-link ${link.Button ? ' buttonThis' : ''}`}>
                    {link.Button ? <Podcasts className='me-2' /> : ''}
                    {link.text}
                  </Link>
                }




              </li>
            ))}
          </ul>

          <div className='__phoneView'>
            <a href="../../WHITEPAPER.pdf" className='btn buttonThis' download> <Podcasts /> White Paper</a>
            <button type='button' className='toggleBtn' onClick={() => { toggleNav() }}> <Sort /></button>

          </div>
        </div>

      </header>

      <div className={`__overlYheader ${isActive ? 'showOvely' : ''}`} onClick={() => { toggleNav() }}></div>
    </>
  )
}

export default Header